<template>
  <div class="finance-announce">
    <div class="logo"></div>
    <div class="center-container">
      <div class="left-content">
        <div class="s-title s-title-b">
          <span class="decoration"></span>
          <span class="title">银行货款</span>
        </div>
      </div>
      <div class="right-content">
        <div class="s-title s-title-b" style="line-height: 35px">
        </div>
      </div>
    </div>

    <div class="bank-list">
      <div class="bank-li" v-for="item of bankList" :key="item.loanCode">
        <!-- <img src="../../assets/bank_logo.png" /> -->
        <div>
          <span>{{ item.loanName }}</span>
          <p>{{ item.bankName }}</p>
        </div>
        <div>
          <span>{{ item.minRate }}%~{{ item.maxRate }}%</span>
          <p>利率参考范围</p>
        </div>
        <div>
          <span>{{ item.minMoney }}万 ~ {{ item.maxMoney }}万</span>
          <p>贷款额度</p>
        </div>
        <div>
          <span>{{ item.loanType }}</span>
          <p>担保方式</p>
        </div>
        <div>
          <span>{{ item.loanDuration }}个月</span>
          <p>贷款期限</p>
        </div>
        <div>
          <el-button type="primary" round>查看详情</el-button>
        </div>
      </div>

      <div class="pages">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="params.currentPage"
          :page-size="params.pageSize"
          :total="total"
          @current-change="currentChange"
          >>
        </el-pagination>
      </div>
    </div>
  </div>
</template>


<script>
import { getBankList } from "@/api/supplyDemand.js";

export default {
  data() {
    return {
      form: {
        bankName: "",
        productName: "",
      },

      total: 0,

      params: {
        pageNum: 1,
        pageSize: 10,
      },

      bankList: [],
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    async getList() {
      let res = await getBankList(this.params);
      if (res.code == 0 && res.data) {
        this.bankList = res.data.records;
        console.log(this.bankList);
      }
    },

    currentChange(val) {
      this.params.pageNum = val;
    },
  },
};
</script>

<style lang="less" scoped>
.logo {
  width: 100%;
  height: 468px;
  background-color: #ffbcbc;
  background-image: url("../../assets/imgs/finance-logo.png");
  background-size: 100% 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.bank-list {
  width: 1472px;
  margin: 0 auto;
  text-align: left;

  .pages {
    margin-top: 16px;
    text-align: right;
  }

  .bank-li {
    display: flex;
    align-items: center;
    margin: 30px 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e2e2e2;

    img {
      height: 42px;
    }

    > div {
      margin-left: 60px;
      flex: 1;

      span {
        font-size: 18px;
        color: #fa8c15;

        // &.red {
        //   color: #fa8c15;
        // }
      }

      p {
        margin: 0;
        font-size: 14px;
        color: #6a6a6a;
      }
    }
  }
}

.finance-announce {
  background: #fff;

  padding-bottom: 10px;
  .table-head-blue {
    margin-top: 49px;
  }
  .pagenation {
    margin-top: 77px;
    text-align: center;
  }
}

.center-container .right-content {
  padding-top: 15px;
}
</style>
